import { useState, useContext, useRef } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { isEmpty } from 'lodash'

//images
import menu from '../../assets/icons/menu.svg'
import times from '../../assets/icons/times.svg'
import logoutIcon from '../../assets/icons/logout.svg'
import wanalytics from '../../assets/icons/analyticsIcon.svg'
import wapprovals from '../../assets/icons/approvals.svg'
import wmap from '../../assets/icons/map.svg'
import worder from '../../assets/icons/orders.svg'
import wreports from '../../assets/icons/reports.svg'
import wclients from '../../assets/icons/clients.svg'
import wusers from '../../assets/icons/users.svg'
import wvehicles from '../../assets/icons/vehicles.svg'
import cartIcon from '../../assets/icons/cart.svg'
import bchev from '../../assets/icons/chevup.svg'
import userIcon from '../../assets/icons/users.svg'

// hooks
import { SessionContext } from '../../context/session/sessionContext'
import { CartContext } from '../../context/cart/cartContext'
import { useQuery } from "../../hooks/useQuery";

export const TopBar = () => {
    const [state, setState] = useState({
        isOpen: false,
        showOptions: '',
        showNotifications: '',
    })
    const [activeContainer, setActiveContainer] = useState(null);
    const containerRef = useRef([]);
    const navigate = useNavigate()
    const location = useLocation()

    const pathname = location.pathname

    const user = JSON.parse(sessionStorage.getItem('session'));

    const sessionContext = useContext(SessionContext);
    const { session, logout } = sessionContext;

    const cartContext = useContext(CartContext);
    const { cartItems } = cartContext;

    const toggleNav = () => setState({ ...state, isOpen: !state.isOpen })

    const toggleContainer = (index) => activeContainer === index ? setActiveContainer(null) : setActiveContainer(index);

    const toggleDropDown = (index) => toggleContainer(index)

    const handleNavigation = (path) => {
        sessionStorage.setItem('tabIndex', path?.slice(-1))

        const activePaths = [
            {
                key: '#O',
                path: '/'
            },
            {
                key: '#A',
                path: '/approvals'
            },
            {
                key: '#N',
                path: '/analytics'
            },
            {
                key: '#R',
                path: '/reports'
            },
            {
                key: '#C',
                path: '/clients'
            },
            {
                key: '#U',
                path: '/users'
            },
            {
                key: '#V',
                path: '/vehicles'
            },
        ];

        const userPath = activePaths?.find(paths => paths?.key === path?.slice(0, 2))?.path;

        setState({ ...state, isOpen: false })

        navigate(`${userPath}`)

        userPath === pathname && window.location.reload()
    }

    const { data, isLoading, error } = useQuery(`${process.env.REACT_APP_ENDPOINT}/api/v1/documents/getlogo`, 'get', 10000);

    if (isLoading) { }

    if (error) { }

    if (isEmpty(data)) {
    }

    const logoURL = `${process.env.REACT_APP_LOGO_ENDPOINT}/${data[0]?.image_name}`

    return (
        <div className='py-8 px-2 md:p-6 flex items-center justify-between h-[7dvh] relative bg-white lg:rounded z-30'>
            <figure className="relative">
                <img
                    src={logoURL}
                    alt='Legend Systems'
                    loading="lazy"
                    className="object-contain w-24 h-24 cursor-pointer"
                />
            </figure>
            <div className='flex items-center justify-end w-11/12 gap-2 lg:gap-4'>
                {/* <Notify /> */}
                <Link to='/cart' className='group'>
                    <figure className="relative w-6 h-6 rounded-full">
                        <img
                            src={cartIcon}
                            alt='Legend Systems'
                            loading="lazy"
                            className="object-contain w-full h-full cursor-pointer"
                        />
                        {(cartItems && !isEmpty(cartItems)) && < p className='absolute top-[-0.5rem] right-[-0.5rem] bg-red text-white h-5 text-xs w-5 rounded-full flex items-center justify-center'>{cartItems.length}</p>}
                    </figure>
                </Link>
                <Link to='/account' className='items-center justify-end hidden gap-0 lg:flex hover:text-black'>
                    <figure className="w-12 h-12 rounded-full">
                        <img
                            src={userIcon}
                            alt='Legend Systems'
                            loading="lazy"
                            className="object-contain w-full h-full cursor-pointer"
                        />
                    </figure>
                    <p className='font-medium'>{session?.name}</p>
                </Link>
                <figure className="w-12 h-12 rounded-full lg:hidden">
                    <img
                        src={menu}
                        alt='Legend Systems'
                        loading="lazy"
                        onClick={toggleNav}
                        className="object-contain w-full h-full cursor-pointer"
                    />
                </figure>
            </div>
            {
                state.isOpen &&
                <div className='absolute top-0 right-0 z-50 flex flex-col justify-between w-full h-screen p-4 mx-auto duration-500 bg-black lg:ease-in-out md:mx-0 lg:hidden'>
                    <ul className="w-full md:w-11/12 mx-auto right-0 text-lg text-black bg-white h-[99%] my-auto rounded-lg px-4 py-6 flex flex-col justify-between lg:ease-in-out duration-500">
                        <div className='flex flex-col justify-start gap-6'>
                            <div className='flex items-center justify-end'>
                                <img src={times} alt="Orders" loading="lazy" className="w-12 cursor-pointer" onClick={toggleNav} />
                            </div>
                            <li>
                                <div
                                    className="flex items-center justify-start gap-2 duration-500 cursor-pointer lg:ease-in-out lg:hover:ml-2"
                                    onClick={() => toggleDropDown(0)}>
                                    <img src={worder} alt="Orders" loading="lazy" className="w-8" />
                                    <p className="flex items-center justify-between w-1/3 gap-2">
                                        Orders
                                        <img src={bchev} alt="Orders" loading="lazy" className={`w-6 ${activeContainer === 0 ? 'rotate-180' : ''} lg:ease-in-out duration-500`} />
                                    </p>
                                </div>
                                <div ref={(ref) => (containerRef.current[0] = ref)}
                                    className={`flex flex-col justify-start gap-2 ml-40 ${activeContainer === 0 ? '' : 'hidden'} fade-in`}>
                                    <p className='text-black hover:text-black' onClick={() => handleNavigation('#O0')}>Drafts</p>
                                    <p className='text-black hover:text-black' onClick={() => handleNavigation('#O1')}>Create New</p>
                                    <p className='text-black hover:text-black' onClick={() => handleNavigation('#O2')}>My Orders</p>
                                    <p className='text-black hover:text-black' onClick={() => handleNavigation('#O3')}>Processing</p>
                                    <p className='text-black hover:text-black' onClick={() => handleNavigation('#O4')}>Completed</p>
                                    <p className='text-black hover:text-black' onClick={() => handleNavigation('#O5')}>Cancelled</p>
                                </div>
                            </li>
                            {
                                (user?.type?.toLowerCase()?.includes('super admin') || user?.type?.toLowerCase()?.includes('dc user')) &&
                                <>
                                    <li>
                                        <div
                                            className="flex items-center justify-start gap-2 duration-500 cursor-pointer lg:ease-in-out lg:hover:ml-2"
                                            onClick={() => toggleDropDown(1)}>
                                            <img src={wapprovals} alt="Approvals" loading="lazy" className="w-8" />
                                            <p className="flex items-center justify-between w-1/3 gap-2">
                                                Approvals
                                                <img src={bchev} alt="Approvals" loading="lazy" className={`w-6 ${activeContainer === 1 && 'rotate-180'} lg:ease-in-out duration-500`} />
                                            </p>
                                        </div>
                                        <div
                                            ref={(ref) => (containerRef.current[1] = ref)}
                                            className={`flex flex-col justify-start gap-2 ml-40 ${activeContainer === 1 ? '' : 'hidden'} fade-in`}>
                                            <p className='text-black hover:text-black' onClick={() => handleNavigation('#A0')}>Commercial</p>
                                            <p className='text-black hover:text-black' onClick={() => handleNavigation('#A1')}>Finance</p>
                                            <p className='text-black hover:text-black' onClick={() => handleNavigation('#A2')}>Stock</p>
                                            <p className='text-black hover:text-black' onClick={() => handleNavigation('#A3')}>Picking</p>
                                            <p className='text-black hover:text-black' onClick={() => handleNavigation('#A4')}>Loading</p>
                                            <p className='text-black hover:text-black' onClick={() => handleNavigation('#A5')}>Delivery</p>
                                        </div>
                                    </li>
                                    <li>
                                        <Link to="/map" className="flex items-center justify-start gap-2 duration-500 cursor-pointer lg:ease-in-out lg:hover:ml-2">
                                            <img src={wmap} alt="Map" loading="lazy" className="w-8" />
                                            <p className="flex items-center justify-between w-1/3 gap-2">
                                                Map
                                            </p>
                                        </Link>
                                    </li>
                                    <li>
                                        <div
                                            className="flex items-center justify-start gap-2 duration-500 cursor-pointer lg:ease-in-out lg:hover:ml-2"
                                            onClick={() => toggleDropDown(2)}>
                                            <img src={wanalytics} alt="Analytics" loading="lazy" className="w-8" />
                                            <p className="flex items-center justify-between w-1/3 gap-2">
                                                Analytics
                                                <img src={bchev} alt="Analytics" loading="lazy" className={`w-6 ${activeContainer === 2 && 'rotate-180'} lg:ease-in-out duration-500`} />
                                            </p>
                                        </div>
                                        <div
                                            ref={(ref) => (containerRef.current[2] = ref)}
                                            className={`flex flex-col justify-start gap-2 ml-40 ${activeContainer === 2 ? '' : 'hidden'} fade-in`}>
                                            <p className='text-black hover:text-black' onClick={() => handleNavigation('#N0')}>General</p>
                                            <p className='text-black hover:text-black' onClick={() => handleNavigation('#N1')}>Store</p>
                                            <p className='text-black hover:text-black' onClick={() => handleNavigation('#N2')}>DC</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div
                                            className="flex items-center justify-start gap-2 duration-500 cursor-pointer lg:ease-in-out lg:hover:ml-2"
                                            onClick={() => toggleDropDown(3)}>
                                            <img src={wreports} alt="Reports" loading="lazy" className="w-8" />
                                            <p className="flex items-center justify-between w-1/3 gap-2">
                                                Reports
                                                <img src={bchev} alt="Reports" loading="lazy" className={`w-6 ${activeContainer === 3 && 'rotate-180'} lg:ease-in-out duration-500`} />
                                            </p>
                                        </div>
                                        <div
                                            ref={(ref) => (containerRef.current[3] = ref)}
                                            className={`flex flex-col justify-start gap-2 ml-40 ${activeContainer === 3 ? '' : 'hidden'} fade-in`}>
                                            <p className='text-black hover:text-black' onClick={() => handleNavigation('#R0')}>Average Distance</p>
                                            <p className='text-black hover:text-black' onClick={() => handleNavigation('#R1')}>Average Delivery Time</p>
                                            <p className='text-black hover:text-black' onClick={() => handleNavigation('#R2')}>Time Spent</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div
                                            className="flex items-center justify-start gap-2 duration-500 cursor-pointer lg:ease-in-out lg:hover:ml-2"
                                            onClick={() => toggleDropDown(4)}>
                                            <img src={wclients} alt="Clients" loading="lazy" className="w-8" />
                                            <p className="flex items-center justify-between w-1/3 gap-2">
                                                Clients
                                                <img src={bchev} alt="Clients" loading="lazy" className={`w-6 ${activeContainer === 4 && 'rotate-180'} lg:ease-in-out duration-500`} />
                                            </p>
                                        </div>
                                        <div
                                            ref={(ref) => (containerRef.current[4] = ref)}
                                            className={`flex flex-col justify-start gap-2 ml-40 ${activeContainer === 4 ? '' : 'hidden'} fade-in`}>
                                            <p className='text-black hover:text-black' onClick={() => handleNavigation('#C0')}>Create Clients</p>
                                            <p className='text-black hover:text-black' onClick={() => handleNavigation('#C1')}>View Clients</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div
                                            className="flex items-center justify-start gap-2 duration-500 cursor-pointer lg:ease-in-out lg:hover:ml-2"
                                            onClick={() => toggleDropDown(5)}>
                                            <img src={wusers} alt="Users" loading="lazy" className="w-8" />
                                            <p className="flex items-center justify-between w-1/3 gap-2">
                                                Users
                                                <img src={bchev} alt="Users" loading="lazy" className={`w-6 ${activeContainer === 5 && 'rotate-180'} lg:ease-in-out duration-500`} />
                                            </p>
                                        </div>
                                        <div
                                            ref={(ref) => (containerRef.current[5] = ref)}
                                            className={`flex flex-col justify-start gap-2 ml-40 ${activeContainer === 5 ? '' : 'hidden'} fade-in`}>
                                            <p className='text-black hover:text-black' onClick={() => handleNavigation('#U0')}>Create Users</p>
                                            <p className='text-black hover:text-black' onClick={() => handleNavigation('#U1')}>View Users</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div
                                            className="flex items-center justify-start gap-2 duration-500 cursor-pointer lg:ease-in-out lg:hover:ml-2"
                                            onClick={() => toggleDropDown(6)}>
                                            <img src={wvehicles} alt="Vehicles" loading="lazy" className="w-8" />
                                            <p className="flex items-center justify-between w-1/3 gap-2">
                                                Vehicles
                                                <img src={bchev} alt="Vehicles" loading="lazy" className={`w-6 ${activeContainer === 6 && 'rotate-180'} lg:ease-in-out duration-500`} />
                                            </p>
                                        </div>
                                        <div
                                            ref={(ref) => (containerRef.current[6] = ref)}
                                            className={`flex flex-col justify-start gap-2 ml-40 ${activeContainer === 6 ? '' : 'hidden'} fade-in`}>
                                            <p className='text-black hover:text-black' onClick={() => handleNavigation('#V0')}>Create Vehicles</p>
                                            <p className='text-black hover:text-black' onClick={() => handleNavigation('#V1')}>View Vehicles</p>
                                        </div>
                                    </li>
                                </>
                            }
                        </div>
                        <div className='flex items-center justify-between'>
                            <Link to='account' className='flex items-center justify-end gap-2'>
                                <figure className="w-10 h-10 rounded-full">
                                    <img
                                        className="object-contain w-full h-full cursor-pointer"
                                        loading="lazy"
                                        alt='Legend Systems'
                                        src={userIcon}
                                    />
                                </figure>
                                <p>{session?.name}</p>
                            </Link>
                            <figure className="w-10 h-10 rounded-full">
                                <img
                                    src={logoutIcon}
                                    alt='Legend Systems'
                                    loading="lazy"
                                    onClick={logout}
                                    className="object-contain w-full h-full cursor-pointer"
                                />
                            </figure>
                        </div>
                    </ul>
                </div>
            }
        </div>
    )
}