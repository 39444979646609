import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";
import { isEmpty } from "lodash";
import { useContext, useState } from "react";
import { DatePickerModal } from "../modules/invoice-viewer/date-picker-modal.js";

//components
import { Layout } from "../layout/layout";
import { Loading, Error, Empty } from "../modules/invoice-viewer/states";
import { OutOfStock } from "../modules/invoice-viewer/out-of-stock";
import { InStock } from "../modules/invoice-viewer/in-stock";

//config
import { config } from "../utils/app-settings.js";

//hooks
import { useQuery } from "../hooks/useQuery";
import { InvoiceTotals } from "../modules/invoice-viewer/invoice-totals";
import { CartContext } from "../context/cart/cartContext";

export const InvoiceViewer = () => {
    const [state, setState] = useState({
        isEditing: ''
    })
    const [startDate, setStartDate] = useState(new Date());

    const { query } = useParams();

    const navigate = useNavigate();

    const cartContext = useContext(CartContext);
    const { addToCart, addStoreToCart } = cartContext;

    const { data, isLoading, error } = useQuery(`${process.env.REACT_APP_ENDPOINT}/api/v1/ordermodel/invoiceinfo/${query}`, "get", 10000);

    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        window.location.reload()
        return <Error />;
    }

    if (isEmpty(data)) {
        window.location.reload()
        return <Empty />;
    }

    //get and sorts addresses
    const getAddress = (obj) => {
        const addressFields = [
            "street_street_address",
            "street_address_complex",
            "street_address_province",
            "street_address_suburb",
            "street_address_city",
            "street_address_country"
        ];

        const address = addressFields
            .map(field => obj[field])
            .filter(Boolean)
            .join(", ");

        return address;
    }

    const address = data && getAddress(data[0])

    const goBack = () => navigate(-1);

    const editOrder = async () => {
        setState({ ...state, isEditing: true })

        try {
            const invoiceItems = await axios.get(`${process.env.REACT_APP_ENDPOINT}/api/v1/ordermodel/items/${query}`, config)

            if (invoiceItems?.data && !isEmpty(invoiceItems?.data)) {
                const storeProducts = await axios.get(`${process.env.REACT_APP_ENDPOINT}/api/v1/items`, config);

                if (storeProducts?.data && !isEmpty(storeProducts)) {

                    const invoiceUIDs = invoiceItems?.data?.map((property) => {
                        return {
                            uid: property.uid,
                        }
                    })

                    function findProduct(allProducts, invoiceProducts) {
                        const matchingProducts = allProducts.filter(product => {
                            return invoiceProducts.some(uidObj => uidObj.uid === product.uid);
                        });

                        return matchingProducts;
                    }

                    const matchingProducts = findProduct(storeProducts?.data, invoiceUIDs);

                    if (matchingProducts && !isEmpty(matchingProducts) && matchingProducts?.length === invoiceUIDs?.length) {

                        for (const product of matchingProducts) {
                            addToCart(product)
                        }

                        const purchasingStore = data?.map((property) => {
                            return {
                                client_company_name: property.client_company_name,
                                uid: property.client_uid,
                            }
                        })

                        addStoreToCart(purchasingStore)

                        toast(`Adding products to your cart, this will take a few seconds....`, {
                            icon: '📦',
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                            duration: 6000,
                        });

                        setTimeout(() => {
                            sessionStorage.setItem('tabIndex', 1)
                            navigate('/');
                        }, 6200);
                    }
                    else {

                        for (const product of matchingProducts) {
                            addToCart(product)
                        }

                        const purchasingStore = data?.map((property) => {
                            return {
                                client_company_name: property.client_company_name,
                                uid: property.client_uid,
                            }
                        })

                        addStoreToCart(purchasingStore)

                        toast('We could not find all the items in the product list', {
                            icon: '✅',
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                            duration: 3000,
                        });

                        setTimeout(() => {
                            sessionStorage.setItem('tabIndex', 1)
                            navigate('/');
                        }, 3200);
                    }
                }
            }
        }
        catch (err) {
            toast('Failed to edit items, please try again', {
                icon: '❌',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });
        }
    };

    const openModal = (setting) => {
        setState((prevState) => ({ ...prevState, isVisible: true, property: setting }));
    };

    const closeModal = () => setState((prevState) => ({ ...prevState, isVisible: false }));

    const cancelOrder = async () => {
        try {
            const cancelOrder = await axios.put(`${process.env.REACT_APP_ENDPOINT}/api/v1/order/cancelorder/${query}`, {}, config);

            if (cancelOrder?.data?.message === "Success") {
                toast(`You have successfully cancelled order No: ${query}.`, {
                    icon: "✅",
                    style: {
                        borderRadius: "10px",
                        background: "#333",
                        color: "#fff",
                    },
                    duration: 3000,
                });

                setTimeout(() => {
                    window.location.reload();
                }, 3100);
            }
        } catch (err) {
            toast(`Failed to cancel order No: ${query}, please try again.`, {
                icon: "❌",
                style: {
                    borderRadius: "10px",
                    background: "#333",
                    color: "#fff",
                },
                duration: 3000,
            });
        }
    };

    const updateDate = async (product, inputValues) => {

        if (!isEmpty(inputValues)) {
            try {

                const payLoad = {
                    delivery_date: inputValues
                }

                const updateDeliveryDate = await axios.put(`${process.env.REACT_APP_ENDPOINT}/api/v1/order/setdeliverydate/${query}`, payLoad, config)

                if (updateDeliveryDate?.data?.message === 'Success') {

                    toast(`Delivery date updated successfully`, {
                        icon: '👋',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                        duration: 3000,
                    });

                    closeModal()

                } else {

                    toast('Failed to update delivery date, please try again', {
                        icon: '❌',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                        duration: 3000,
                    });

                    closeModal()
                }
            }
            catch (error) {

                toast('Error updating delivery date, try again later', {
                    icon: '❌',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });

                closeModal()

            }
        } else {
            toast('Error please select a date to apply', {
                icon: '❌',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });
        }
    };

    return (
        <Layout>
            <section className="w-full h-[89vh] flex flex-col justify-start items-start gap-6 overflow-x-hidden relative p-2">
                <div className="flex items-center justify-between w-full lg:h-[15%] flex-wrap gap-2 lg:gap-2">
                    <div className="flex flex-col justify-start gap-0">
                        <p className="text-2xl font-semibold text-purple lg:text-3xl">
                            Purchase Order
                        </p>
                        {data?.map((invoice) => <p className="text-lg font-bold text-gray-500">#{invoice.order_number}</p>)}
                    </div>
                    <div className="flex flex-wrap items-center justify-start w-full gap-2 lg:justify-end lg:w-6/12">
                        <button className="border border-red p-1 md:p-2 w-[30%] md:w-[20%] text-red hover:bg-red hover:text-white lg:ease-in-out duration-300 rounded cursor-pointer" onClick={cancelOrder}>Cancel</button>
                        <button className="border border-purple p-1 md:p-2 w-[30%] md:w-[20%] text-purple hover:bg-purple hover:text-white lg:ease-in-out duration-300 rounded cursor-pointer" onClick={editOrder} disabled={state.isEditing === true}>Reorder</button>
                        <button className="border border-green p-1 md:p-2 w-[30%] md:w-[20%] text-green hover:bg-green hover:text-white lg:ease-in-out duration-300 rounded cursor-pointer" onClick={goBack}>Close</button>
                    </div>
                </div>
                <hr className="bg-black-light p-[0.5px] w-full" />
                <div className="flex items-start justify-start w-full flex-wrap gap-6 h-[95%] overflow-y-scroll pr-2 pb-10">
                    {data?.map((invoice) => (
                        <div className="flex flex-wrap items-start justify-start w-full gap-6 md:gap-0">
                            <div className="flex flex-col justify-start w-full gap-2 md:w-5/12 lg:w-1/3">
                                <p className="font-semibold text-gray-500 text-md">
                                    Invoice Details
                                </p>
                                <div className="flex items-center justify-between w-full">
                                    <ul className="flex flex-col items-start justify-start w-1/2 gap-1">
                                        <p className="text-sm font-medium text-gray-500">
                                            Operation:
                                        </p>
                                        <p className="text-sm font-medium text-gray-500">
                                            Number:
                                        </p>
                                        <p className="text-sm font-medium text-gray-500">
                                            Status:
                                        </p>
                                        <p className="text-sm font-medium text-gray-500">
                                            Stage:
                                        </p>
                                        <p className="text-sm font-medium text-gray-500">
                                            Invoice Number:
                                        </p>
                                        <p className="text-sm font-medium text-gray-500">
                                            Creation Date:
                                        </p>
                                        <p className="text-sm font-medium text-gray-500">
                                            Delivery Date:
                                        </p>
                                        <p className="text-sm font-medium text-gray-500">
                                            Amount of Items:
                                        </p>
                                        <p className="text-sm font-medium text-gray-500">
                                            Discount Percentage:
                                        </p>
                                        <p className="text-sm font-medium text-gray-500">
                                            Discount Amount:
                                        </p>
                                    </ul>
                                    <ul className="flex flex-col items-start justify-start w-1/2 gap-1">
                                        <li className="w-full text-sm font-medium leading-none text-gray-500 rounded lg:w-3/4">
                                            {invoice.dc_name ? invoice.dc_name : 'Missing'}
                                        </li>
                                        <li className="w-full text-sm font-medium text-gray-500 rounded lg:w-3/4">
                                            {invoice.order_number ? invoice.order_number : 'Missing'}
                                        </li>
                                        <li className={`rounded w-full lg:w-3/4 text-gray-500 uppercase font-medium text-sm ${invoice.status?.toLowerCase()?.includes("in progress") || invoice.status?.toLowerCase()?.includes("on hold") ? "text-yellow" : invoice.status?.toLowerCase()?.includes("cancelled") ? "text-red" : invoice.status?.toLowerCase()?.includes("completed") || invoice.status?.toLowerCase()?.includes("delivery") || invoice.status?.toLowerCase()?.includes("delivered") ? "text-green" : ""}`}>
                                            {invoice.status ? invoice.status : 'Missing'}
                                        </li>
                                        <li className={`rounded w-full lg:w-3/4 text-gray-500 uppercase font-medium text-sm ${invoice.stage?.toLowerCase()?.includes('in progress') ? "text-yellow" : invoice.stage?.toLowerCase()?.includes("commercial") ? "text-purple" : invoice.stage?.toLowerCase()?.includes("completed") || invoice.stage?.toLowerCase()?.includes("delivery") || invoice.stage?.toLowerCase()?.includes("delivered") ? "text-green" : invoice.stage?.toLowerCase()?.includes("cancelled") ? "text-red" : invoice.stage?.toLowerCase()?.includes("picking") ? "text-purple" : invoice.stage?.toLowerCase() === "stock" ? "text-blue" : ""}`}>
                                            {invoice.stage ? invoice.stage : 'Missing'}
                                        </li>
                                        <li className="w-full text-sm font-medium text-gray-500 rounded lg:w-3/4">
                                            {invoice.order_number ? invoice.order_number : 'Missing'}
                                        </li>
                                        <li className="w-full text-sm font-medium text-gray-500 rounded lg:w-3/4">
                                            {invoice.creation_date ? invoice.creation_date?.replace("T", " ").replace(".000Z", "") : '0000-00-00'}
                                        </li>
                                        <li className={`rounded w-full lg:w-3/4 text-sm font-medium ${invoice.delivery_date === "0000-00-00 00:00:00.000000" ? 'text-red' : 'text-gray-500'}`}>
                                            {
                                                invoice.delivery_date !== "0000-00-00 00:00:00.000000" ?
                                                    invoice.delivery_date?.replace("T", " ").replace(".000Z", "") :
                                                    <button onClick={() => openModal(invoice)} className="border border-purple px-6 py-1 text-sm text-purple hover:bg-purple hover:text-white lg:ease-in-out duration-300 rounded cursor-pointer">Add Delivery Date</button>
                                            }
                                        </li>
                                        <li className="w-full text-sm font-medium text-gray-500 rounded lg:w-3/4">
                                            {invoice.count ? invoice.count : 0}
                                        </li>
                                        <li className="w-full text-sm font-medium text-gray-500 rounded lg:w-3/4">
                                            {invoice.discount_percentage ? invoice.discount_percentage : "0%"}
                                        </li>
                                        <li className="w-full text-sm font-medium text-gray-500 rounded lg:w-3/4">
                                            {invoice.discount_percentage ? invoice.discount_percentage : "0%"}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="flex flex-col justify-start w-full gap-2 md:w-5/12 lg:w-1/2">
                                <p className="font-semibold text-gray-500 text-md">
                                    Entity Details
                                </p>
                                <div className="flex items-center justify-start w-full">
                                    <ul className="flex flex-col items-start justify-start w-1/2 gap-1">
                                        <li className="text-sm font-medium text-gray-500">UID:</li>
                                        <li className="text-sm font-medium text-gray-500">
                                            Created by:
                                        </li>
                                        <li className="text-sm font-medium text-gray-500">
                                            Address:
                                        </li>
                                        <li className="text-sm font-medium text-gray-500">
                                            Active:
                                        </li>
                                        <li className="text-sm font-medium text-gray-500">
                                            Warehouse:
                                        </li>
                                        <li className="text-sm font-medium text-gray-500">
                                            Pending Orders:
                                        </li>
                                    </ul>
                                    <ul className="flex flex-col items-start justify-start w-full gap-1">
                                        <li className="w-full text-sm font-medium text-gray-500 rounded lg:w-3/4">
                                            {invoice.order_number ? invoice.order_number : 'Missing'}
                                        </li>
                                        <li className="w-full text-sm font-medium text-gray-500 rounded lg:w-3/4">
                                            {invoice.created_by ? invoice.created_by : 'Missing'}
                                        </li>
                                        <li className="w-full text-sm font-medium text-gray-500 rounded lg:w-full">{address ? address : 'Missing address'}</li>
                                        <li className="w-full text-sm font-medium text-gray-500 rounded lg:w-3/4">
                                            {invoice.active ? invoice.active : 'Missing'}
                                        </li>
                                        <li className="w-full text-sm font-medium text-gray-500 rounded lg:w-3/4">
                                            {invoice.dc_name ? invoice.dc_name : 'Missing'}
                                        </li>
                                        <li className="w-full text-sm font-medium text-gray-500 rounded lg:w-3/4">
                                            {isEmpty(invoice.Pending_Orders?.toString()) ? "No pending orders" : "R " + invoice.Pending_Orders?.toLocaleString(undefined, { minimumFractionDigits: 2 })}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ))}
                    <hr className="bg-black-light p-[0.5px] w-full" />
                    <InStock invoiceStage={data[0]?.stage} />
                    <hr className="bg-black-light p-[0.5px] w-full" />
                    <OutOfStock invoiceStage={data[0]?.stage} />
                    <hr className="bg-black-light p-[0.5px] w-full" />
                    <InvoiceTotals />
                    {state.isVisible && <DatePickerModal category={state.property} closeModal={closeModal} updateDate={updateDate} />}
                </div>
            </section>
        </Layout>
    );
}