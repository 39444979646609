import { useState } from "react";
import { toast } from 'react-hot-toast';
import Validator from 'validatorjs';
import { isEmpty } from "lodash";
import moment from "moment";
import axios from "axios";

//hooks
import { InputHandler } from "../../hooks/useInputHandler";

//components
import { Roles } from "./../users/sections/roles";
import { StoreTypes } from "./sections/store-types";
import { AddressAutocomplete } from "../users/sections/address-autocomplete";
// import { PriceList } from "./sections/price-list";

//images
import loadingIcon from "../../assets/icons/wloader.svg";

//valid data entry
const validEntry = {
    userID: 'required',
    companyName: 'required',
    regCompanyName: 'required',
    regNumber: 'required',
    vatNumber: 'required',
    contactPerson: 'required',
    contactNumber: 'required',
    email: 'required|email',
    role: 'required',
    dTime: 'required|integer',
    storeType: 'required',
    creditLimit: 'required|integer',
    creditWarning: 'required|integer',
    businessAdress: 'required',
    shippingAdress: 'required',
    // tier: 'required',
}

export const CreateClient = () => {
    const [state, setState] = useState({
        role: '',
        division: '',
        userType: '',
        assignedStores: '',
        activeStatus: 1,
        isLoading: '',
        errorID: '',
        errorUsername: '',
        businessAdress: [],
        shippingAdress: [],
        operationCompleted: '',
        // tier: null
    })

    const [errors, setErrors] = useState({});

    const { inputValues, clearValues, handleChange } = InputHandler();

    //grabs and sets values from drop down menus
    const handleRoles = (params) => setState({ ...state, role: params })
    const handleAssignedStores = (params) => setState({ ...state, assignedStores: params })
    const handleAssignedTiers = (params) => setState({ ...state, tier: params })

    const handleAddress = async (address) => isEmpty(state.businessAdress) ? setState({ ...state, businessAdress: address }) : setState({ ...state, shippingAdress: address })

    const createClient = async (e) => {
        e.preventDefault()

        const totalData = Object.assign({}, inputValues, {
            storeType: state.assignedStores?.uid,
            role: state.role?.uid,
            businessAdress: state.businessAdress,
            shippingAdress: state.shippingAdress,
            tier: state.tier,
        })

        const validation = new Validator(totalData, validEntry);

        if (validation.fails()) {
            setErrors(validation.errors.all());
            return;
        }

        if (validation.passes()) {
            try {
                setErrors('')
                setState({ ...state, isLoading: true, })

                const payLoad = {
                    uid: inputValues?.userID,
                    company_name: inputValues?.companyName,
                    registered_name: inputValues?.regCompanyName,
                    vat_number: inputValues?.vatNumber,
                    active: 1,
                    last_order_date: moment().format('YYYY-MM-DD'),
                    creation_date: moment().format('YYYY-MM-DD'),
                    approved_credit_limit: inputValues?.creditLimit,
                    credit_limit_warning: inputValues?.creditWarning,
                    latitude: state.businessAdress?.coords?.lat,
                    longitude: state.businessAdress?.coords?.lng,
                    store_type: state.assignedStores?.uid,
                    delivery_target_time: inputValues?.dTime,
                    tier_uid: state.tier?.uid,
                    business_street_address: state.businessAdress?.fullAddress?.STREET,

                    // fields left blank are not used in the app
                    business_address_complex: '',
                    business_address_province: '',
                    business_address_suburb: state.businessAdress?.fullAddress?.SUBURB,
                    business_address_city: state.businessAdress?.fullAddress?.CITY,
                    business_address_postal_code: state.businessAdress?.postalCode,
                    business_address_reg_number: inputValues?.regNumber,

                    street_street_address: state.businessAdress?.fullAddress?.STREET,
                    street_address_complex: '',
                    street_address_province: '',
                    street_address_suburb: state.businessAdress?.fullAddress?.SUBURB,
                    street_address_city: state.businessAdress?.fullAddress?.CITY,
                    street_address_postal_code: state.businessAdress?.postalCode,
                };

                const session = JSON.parse(sessionStorage.getItem('session'));

                const config = {
                    headers: {
                        'token': session?.token,
                        mode: 'no-cors'
                    }
                };

                console.log(payLoad)
                const createClient = await axios.post(`${process.env.REACT_APP_ENDPOINT}/api/v1/client/create`, payLoad, config)
                console.log(createClient?.data)

                if (createClient?.status === 200 && createClient?.data?.affectedRows >= 1) {
                    const payLoad = {
                        uid: inputValues?.userID,
                        name: inputValues?.contactPerson,
                        role: state.role?.uid,
                        contact_number: inputValues?.contactNumber,
                        email_address: inputValues?.email,
                    }

                    const assignUser = await axios.post(`${process.env.REACT_APP_ENDPOINT}/api/v1/contact/createcontact`, payLoad, config)

                    if (assignUser?.data?.message === "Clientcontact Created Successfully") {

                        const payLoad = {
                            fk_composite_contact_uid: inputValues?.userID,
                            fk_composite_client_uid: inputValues?.userID,
                        }

                        const assignComposites = await axios.post(`${process.env.REACT_APP_ENDPOINT}/api/v1/clientcontact`, payLoad, config)

                        if (assignComposites?.data?.message === 'Clientcontact Created Successfully') {
                            toast(`Client created successfully`, {
                                icon: '👋',
                                style: {
                                    borderRadius: '10px',
                                    background: '#333',
                                    color: '#fff',
                                },
                                duration: 3000,
                            });

                            clearValues()

                            setState({
                                ...state,
                                role: '',
                                division: '',
                                userType: '',
                                assignedStores: '',
                                status: 1,
                                activeStatus: 1,
                                errorID: '',
                                errorUsername: '',
                                bAddress: '',
                                dAddress: '',
                                isLoading: false,
                                operationCompleted: 'completed'
                            })
                        }
                        else {
                            setState({ ...state, isLoading: false })

                            toast('Failed to create client, please try again', {
                                icon: '❌',
                                style: {
                                    borderRadius: '10px',
                                    background: '#333',
                                    color: '#fff',
                                },
                                duration: 3000,
                            });
                        }
                    }
                    else {
                        setState({ ...state, isLoading: false })

                        toast('Failed to create client, please try again', {
                            icon: '❌',
                            style: {
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                            },
                            duration: 3000,
                        });
                    }

                }
                else {
                    setState({ ...state, isLoading: false })

                    toast('Failed to create client, please try again', {
                        icon: '❌',
                        style: {
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                        },
                        duration: 3000,
                    });
                }
            }
            catch (error) {
                setState({ ...state, isLoading: false })

                toast('Failed to create client, please try again', {
                    icon: '❌',
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                    duration: 3000,
                });
            }
        }
    }

    return (
        <div className="w-full md:h-[83.5vh] overflow-y-scroll lg:overflow-hidden mb-32 lg:mb-0 pr-1">
            <form className='flex flex-col items-start justify-between w-full h-full gap-4' onSubmit={createClient}>
                <div className="flex flex-col justify-start gap-6">
                    <div className='flex items-center justify-between'>
                        <div className='flex flex-col justify-start w-full gap-0'>
                            <p className="text-2xl font-semibold text-purple lg:text-3xl">
                                Create Client
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-wrap items-start justify-start gap-2">
                        <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                            <label className="text-base font-medium text-gray-500">User ID</label>
                            <input
                                type='number'
                                name="userID"
                                placeholder="LS27"
                                onChange={handleChange}
                                value={inputValues.userID || ''}
                                className={`w-full text-sm text-gray-500 font-medium p-2 md:p-3 rounded placeholder:font-normal placeholder:italic placeholder:text-sm shadow ${state.errorID && 'border border-red'}`}
                            />
                            {errors.userID && <span className="text-[var(--red)] text-sm">{errors.userID[0]}</span>}
                        </div>
                        <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                            <label className="text-base font-medium text-gray-500">Company Name</label>
                            <input
                                type='text'
                                name="companyName"
                                placeholder="Otima Plastics"
                                onChange={handleChange}
                                value={inputValues.companyName || ''}
                                className="w-full p-2 text-sm font-medium text-gray-500 rounded shadow md:p-3 placeholder:font-normal placeholder:italic placeholder:text-sm"
                            />
                            {errors.companyName && <span className="text-[var(--red)] text-sm">{errors.companyName[0]}</span>}
                        </div>
                        <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                            <label className="text-base font-medium text-gray-500">Registered Company Name</label>
                            <input
                                type='text'
                                name="regCompanyName"
                                placeholder="Otima"
                                onChange={handleChange}
                                value={inputValues.regCompanyName || ''}
                                className="w-full p-2 text-sm font-medium text-gray-500 rounded shadow md:p-3 placeholder:font-normal placeholder:italic placeholder:text-sm"
                            />
                            {errors.regCompanyName && <span className="text-[var(--red)] text-sm">{errors.regCompanyName[0]}</span>}
                        </div>
                        <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                            <label className="text-base font-medium text-gray-500">REG Number</label>
                            <input
                                type='test'
                                name="regNumber"
                                placeholder="9898770GH9"
                                onChange={handleChange}
                                value={inputValues.regNumber || ''}
                                className="w-full p-2 text-sm font-medium text-gray-500 rounded shadow md:p-3 placeholder:font-normal placeholder:italic placeholder:text-sm"
                            />
                            {errors.regNumber && <span className="text-[var(--red)] text-sm">{errors.regNumber[0]}</span>}
                        </div>
                        <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                            <label className="text-base font-medium text-gray-500">VAT Number</label>
                            <input
                                type='text'
                                name="vatNumber"
                                placeholder="60691300078"
                                onChange={handleChange}
                                value={inputValues.vatNumber || ''}
                                className="w-full p-2 text-sm font-medium text-gray-500 rounded shadow md:p-3 placeholder:font-normal placeholder:italic placeholder:text-sm"
                            />
                            {errors.vatNumber && <span className="text-[var(--red)] text-sm">{errors.vatNumber[0]}</span>}
                        </div>
                        <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                            <label className="text-base font-medium text-gray-500">Contact Person</label>
                            <input
                                type='text'
                                name="contactPerson"
                                placeholder="John Doe"
                                onChange={handleChange}
                                value={inputValues.contactPerson || ''}
                                className="w-full p-2 text-sm font-medium text-gray-500 rounded shadow md:p-3 placeholder:font-normal placeholder:italic placeholder:text-sm"
                            />
                            {errors.contactPerson && <span className="text-[var(--red)] text-sm">{errors.contactPerson[0]}</span>}
                        </div>
                        <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                            <label className="text-base font-medium text-gray-500">Contact Number</label>
                            <input
                                type='number'
                                name="contactNumber"
                                placeholder="011 236 9078"
                                onChange={handleChange}
                                value={inputValues.contactNumber || ''}
                                className="w-full p-2 text-sm font-medium text-gray-500 rounded shadow md:p-3 placeholder:font-normal placeholder:italic placeholder:text-sm"
                            />
                            {errors.contactNumber && <span className="text-[var(--red)] text-sm">{errors.contactNumber[0]}</span>}
                        </div>
                        <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                            <label className="text-base font-medium text-gray-500">Email</label>
                            <input
                                type='email'
                                name="email"
                                placeholder="jd@example.com"
                                onChange={handleChange}
                                value={inputValues.email || ''}
                                className="w-full p-2 text-sm font-medium text-gray-500 rounded shadow md:p-3 placeholder:font-normal placeholder:italic placeholder:text-sm"
                            />
                            {errors.email && <span className="text-[var(--red)] text-sm">{errors.email[0]}</span>}
                        </div>
                        <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                            <label className="text-base font-medium text-gray-500">Delivery Target Time <span className="text-sm">(mins)</span></label>
                            <input
                                type='number'
                                name="dTime"
                                placeholder="10"
                                onChange={handleChange}
                                value={inputValues.dTime || ''}
                                className="w-full p-2 text-sm font-medium text-gray-500 rounded shadow md:p-3 placeholder:font-normal placeholder:italic placeholder:text-sm"
                            />
                            {errors.dTime && <span className="text-[var(--red)] text-sm">{errors.dTime[0]}</span>}
                        </div>
                        <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                            <label className="text-base font-medium text-gray-500">Role</label>
                            <Roles handleRoles={handleRoles} />
                            {errors.role && <span className="text-[var(--red)] text-sm">{errors.role[0]}</span>}
                        </div>
                        <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                            <label className="text-base font-medium text-gray-500">Store Type</label>
                            <StoreTypes handleAssignedStores={handleAssignedStores} />
                            {errors.storeType && <span className="text-[var(--red)] text-sm">{errors.storeType[0]}</span>}
                        </div>
                    </div>
                    <div className="flex flex-wrap items-start justify-start w-full gap-2">
                        <div className="w-full border-b-2 border-purple"></div>
                        <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                            <label className="text-base font-medium text-gray-500">Business Address</label>
                            <AddressAutocomplete handleAddress={handleAddress} />
                            {errors.businessAdress && <span className="text-[var(--red)] text-sm">{errors.businessAdress[0]}</span>}
                        </div>
                        <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                            <label className="text-base font-medium text-gray-500">Shipping Address</label>
                            <AddressAutocomplete handleAddress={handleAddress} />
                            {errors.shippingAdress && <span className="text-[var(--red)] text-sm">{errors.shippingAdress[0]}</span>}
                        </div>
                    </div>
                    <div className="flex flex-wrap items-start justify-start w-full gap-2">
                        <div className="w-full border-b-2 border-purple"></div>
                        <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                            <label className="text-base font-medium text-gray-500">Credit Warning</label>
                            <input
                                type='number'
                                name="creditWarning"
                                placeholder="R200 000"
                                onChange={handleChange}
                                value={inputValues.creditWarning || ''}
                                className="w-full p-2 text-sm font-medium text-gray-500 rounded shadow md:p-3 placeholder:font-normal placeholder:italic placeholder:text-sm"
                            />
                            {errors.creditWarning && <span className="text-[var(--red)] text-sm">{errors.creditWarning[0]}</span>}
                        </div>
                        <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                            <label className="text-base font-medium text-gray-500">Approved Credit Limit</label>
                            <input
                                type='number'
                                name="creditLimit"
                                placeholder="R250 000"
                                onChange={handleChange}
                                value={inputValues.creditLimit || ''}
                                className="w-full p-2 text-sm font-medium text-gray-500 rounded shadow md:p-3 placeholder:font-normal placeholder:italic placeholder:text-sm"
                            />
                            {errors.creditLimit && <span className="text-[var(--red)] text-sm">{errors.creditLimit[0]}</span>}
                        </div>
                        {/* <div className="flex flex-col justify-start gap-1 w-full sm:w-[48%] md:w-[30%] lg:w-[24%] lg:ease-in-out duration-300">
                            <label className="text-base font-medium text-gray-500">Price List Tier</label>
                            <PriceList handleAssignedTiers={handleAssignedTiers} />
                            {errors.storeType && <span className="text-[var(--red)] text-sm">{errors.storeType[0]}</span>}
                        </div> */}
                    </div>
                    <div className="flex flex-wrap items-start justify-start w-full -mt-2">
                        <button type='submit' className='bg-green text-white py-2 px-10 rounded cursor-pointer text-[1.2rem]'>
                            {
                                state.isLoading ?
                                    <p className='flex items-center justify-center gap-2'>
                                        Creating
                                        <img src={loadingIcon} alt='Loading' loading='lazy' className='w-6 animate-spin' />
                                    </p>
                                    :
                                    <p className='flex items-center justify-center gap-2'>
                                        Create Client
                                    </p>
                            }
                        </button>
                    </div>
                </div>
            </form >
        </div >
    )
}