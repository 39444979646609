import React, { useRef, useState } from 'react';
import { InputHandler } from '../../hooks/useInputHandler';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export const DatePickerModal = ({ category, closeModal, updateDate }) => {
    const { inputValues, handleChange } = InputHandler();
    const [state, setState] = useState({
        date: null
    })

    const assignDate = (date) => {
        setState({ ...state, date: date })
    }

    return (
        <div className={`w-full absolute top-56 transform -translate-y-full flex items-center justify-center rounded-lg fade-in lg:ease-in-out duration-500`}>
            <div className='max-w-sm w-full bg-white p-4 rounded-md flex flex-col justify-center gap-4'>
                <div className='w-full h-full flex flex-col justify-start gap-4'>
                    <p className='font-medium text-xl text-center'>Add Delivery Date</p>
                    <div className='flex items-center justify-center gap-2'>
                        <DatePicker
                            placeholderText='Choose Date'
                            dateFormat="yyyy-MM-dd"
                            selected={inputValues.date}
                            onChange={(date) => {
                                const formattedDate = date.toISOString().split('T')[0];
                                handleChange({ target: { name: 'date', value: formattedDate } });
                                assignDate(formattedDate);
                            }} className='w-full h-8 rounded text-sm text-purple placeholder-purple border-purple text-center'
                        />
                    </div>
                </div>
                <div className='flex items-center justify-between'>
                    <button
                        className='bg-white text-red border border-red hover:bg-red hover:text-white rounded px-4 py-1 lg:ease-in-out duration-300 cursor-pointer w-1/3 text-[1.2rem] font-medium'
                        onClick={closeModal}
                    >
                        Close
                    </button>
                    <button
                        className='bg-white text-green border border-green hover:bg-green hover:text-white rounded px-4 py-1 lg:ease-in-out duration-300 cursor-pointer w-1/3 text-[1.2rem] font-medium'
                        onClick={() => updateDate(category, state?.date)}
                    >
                        Apply
                    </button>
                </div>
            </div>
        </div>
    );
};