import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { isEmpty } from 'lodash';
import { CartContext } from '../../../context/cart/cartContext';

//hooks
import { useQuery } from '../../../hooks/useQuery';

// Images
import placeholder from '../../../assets/icons/placeholder.svg';
import addCart from '../../../assets/icons/grayadd.svg';
import cartTick from '../../../assets/icons/ticksquare.svg';
import promo from '../../../assets/icons/promo-banner.svg';
import cartgadd from '../../../assets/icons/grayadd.svg'
import cartremove from '../../../assets/icons/grayremove.svg'

//loading components
import { EmptyStore } from './states/empty';
import { StoreError } from './states/error';
import { LoadingStore } from './states/loading';

export const Products = ({ params }) => {
    const [isButtonDisabled, setButtonDisabled] = useState(false);

    const user = JSON.parse(sessionStorage.getItem('session'));

    const cartContext = useContext(CartContext);
    const { cartItems, addToCart, store, editCartItem, incrementItem, decrementItem } = cartContext;

    const handleIncrement = (itemId) => incrementItem(itemId)

    const handleDecrement = (itemId) => decrementItem(itemId);

    // const { data, isLoading, error } = useQuery(`${process.env.REACT_APP_ENDPOINT}/${!store?.uid ? 'api/v1/items' : `api/v1/items/itemrateofsalecount/${user?.ROS_Min}/${user?.ROS_Average}/${user?.ROS_Max}/${user?.uid}/${store?.uid}`}`, 'get', 200000);

    const { data, isLoading, error } = useQuery(`${process.env.REACT_APP_ENDPOINT}/api/v1/items`, 'get', 200000);

    const getCartItemQuantity = (uid) => {
        if (uid) {
            const cartItem = cartItems?.find((item) => item.uid === uid);
            return cartItem ? cartItem?.quantity : 0;
        }
        return 0;
    };

    useEffect(() => {
        getCartItemQuantity()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const cartCheck = (product) => {
        if (store) {
            setButtonDisabled(true);

            addToCart(product)

            setTimeout(() => {
                setButtonDisabled(false);
            }, 20);
        }
        else {
            toast('Please select a store first', {
                icon: '❌',
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
                duration: 3000,
            });
        }
    }

    if (isLoading) {
        return (
            <LoadingStore />
        );
    }

    if (error) {
        return (
            <StoreError />
        )
    }

    if (isEmpty(data)) {
        return (
            <EmptyStore content={'The store seems empty please refresh'} />
        )
    }

    const categoryGrouped = (params.category && params.category !== 'ALL CATEGORIES') ? data?.filter(item => item?.description?.toLowerCase().includes(params?.category?.toLowerCase())) : [];

    const searchTerm = params?.searchTerm

    const searchProducts = (productsArray, searchTerm) => {

        const matchingProducts = productsArray?.filter((product) => {
            const propertyValues = Object?.values(product)?.map((value) =>
                typeof value === 'string' ? value?.toLowerCase() : value
            );

            return propertyValues?.some(
                (value) => typeof value === 'string' && value?.includes(searchTerm?.toLowerCase())
            );
        });

        return matchingProducts;
    };

    const matchingProducts = searchProducts(data, searchTerm);

    const products = categoryGrouped.length >= 1 ? categoryGrouped : matchingProducts.length >= 1 ? matchingProducts : data;

    if (isEmpty(products)) {
        return (
            <EmptyStore content={'We do not have the searched product(s)'} />
        )
    }

    const handleQuantityChange = (event, itemId) => {
        const { value } = event.target;

        const parsedValue = parseInt(value, 10);

        if (!isNaN(parsedValue) && parsedValue >= 0) {
            editCartItem(itemId, parsedValue);
        }
    };

    return (
        <>
            {products.map((product) => (
                <div className={`w-[48%] mx-auto md:w-[24%] lg:w-[16%] ${store?.uid ? 'h-[320px]' : 'h-[320px]'} shadow-2xl rounded p-2 cursor-pointer flex flex-col justify-start gap-2 relative bg-white ease-in-out duration-300`} key={product.uid}>
                    {
                        product.special === 1 &&
                        <figure className='absolute top-0 right-0'>
                            <img src={promo} alt='On Promotion' loading='lazy' className='w-28' />
                        </figure>
                    }
                    <div className={`w-full h-1/2 rounded-lg border-2 flex justify-center items-center`}>
                        <figure className='flex items-center justify-center w-full h-full'>
                            <img
                                src={`${process.env.REACT_APP_IMAGE_ENDPOINT}/${product.uid}.jpg`}
                                onError={(e) => {
                                    e.target.onError = null;
                                    e.target.src = placeholder;
                                }}
                                alt=''
                                loading='lazy' className='object-contain w-full h-full' />
                        </figure>
                    </div>
                    <div className='h-[140px] w-full flex flex-col justify-between'>
                        <div className='flex flex-col justify-start gap-1 md:gap-2'>
                            <p className='font-medium leading-none text-gray-700 text-md'>{product.name}</p>
                            <p className='text-sm font-medium leading-none text-gray-500 uppercase'>{product.description}</p>
                        </div>
                        <div className='flex items-center justify-between'>
                            <p className='text-sm font-medium text-gray-500'>{product.packsize}</p>
                            {(user?.type?.toLowerCase()?.includes('super admin') || user?.type?.toLowerCase()?.includes('dc user')) && <p className={`text-gray-500 font-medium ${product.stock <= 0 && 'text-red'}`}> {product.stock >= 1 ? `DC SOH: ${product.stock}` : 'Out of Stock'}</p>}
                            {/* {
                                store?.uid && (user?.type?.toLowerCase()?.includes('store user')) && <p className={`text-gray-500 font-medium ${product.soh <= 0 && 'text-red'}`}> {product.soh >= 1 ? `My SOH: ${product.soh}` : 'Out Of Stock'}</p>
                            } */}
                        </div>
                        <div className='flex items-center justify-between w-full'>
                            <p className='text-xl font-medium'>R {product.special === 1 ? product.special_price.toLocaleString(undefined, { minimumFractionDigits: 2 }) : product.incsell.toLocaleString(undefined, { minimumFractionDigits: 2 })}</p>
                            {
                                cartItems.some(item => item.uid === product.uid) ?
                                    <div className='flex items-center justify-start gap-1'>
                                        <button className='cursor-pointer' onClick={() => handleDecrement(product.uid)}>
                                            <img src={cartremove} alt='' loading='lazy' className='w-6' />
                                        </button>
                                        <input
                                            type='text'
                                            name='quantity'
                                            value={getCartItemQuantity(product.uid)}
                                            className='text-black border-green lg:ease-in-out duration-300 flex items-center justify-center text-center w-12 border-[1px] hover:border-green focus:outline-green outline-none text-sm placeholder:not-italic placeholder:text-black'
                                            onInput={(e) => handleQuantityChange(e, product.uid)}
                                        />
                                        <button className='w-6 cursor-pointer' onClick={() => handleIncrement(product.uid)}>
                                            <img src={cartgadd} alt='' loading='lazy' className='w-full' />
                                        </button>
                                    </div>
                                    :
                                    <button onClick={() => cartCheck(product)} disabled={isButtonDisabled} className='duration-300 cursor-pointer lg:ease-in-out'>
                                        <img src={cartItems.some(item => item.uid === product.uid) ? cartTick : addCart} alt='Add to cart' loading='lazy' className={`${cartItems.some(item => item.uid === product.uid) && 'opacity-30'} w-10 lg:ease-in-out duration-300 cursor-pointer`} />
                                    </button>
                            }
                        </div>
                    </div>
                    {/* {
                        store?.uid &&
                        <div className='h-[50px] flex items-start justify-between gap-1'>
                            <div className='flex flex-col items-center justify-start w-3/12 gap-1'>
                                <span className='w-full h-1 rounded-lg bg-red'></span>
                                <p className='text-sm font-medium leading-none text-center text-gray-500'>{user?.ROS_Min} Days</p>
                                <p className='text-sm font-medium leading-none text-center text-gray-500'>{product.Min >= 0 ? product.Min : 0}</p>
                            </div>
                            <div className='flex flex-col items-center justify-start w-3/12 gap-1'>
                                <span className='w-full h-1 rounded-lg bg-yellow'></span>
                                <p className='text-sm font-medium leading-none text-center text-gray-500'>{user?.ROS_Average} Days</p>
                                <p className='text-sm font-medium leading-none text-center text-gray-500'>{product.Average >= 0 ? product.Average : 0}</p>
                            </div>
                            <div className='flex flex-col items-center justify-start w-3/12 gap-1'>
                                <span className='w-full h-1 rounded-lg bg-green'></span>
                                <p className='text-sm font-medium leading-none text-center text-gray-500'>{user?.ROS_Max} Days</p>
                                <p className='text-sm font-medium leading-none text-center text-gray-500'>{product.Max >= 0 ? product.Max : 0}</p>
                            </div>
                        </div>
                    } */}
                </div>
            ))}
        </>
    );
};
